<script setup lang="ts">
import { StructureProps } from '@/interfaces';

const emit = defineEmits<{
  (e: 'dataManipulation'): void;
}>();

const props = defineProps<{
  input: StructureProps['input'];
  data: StructureProps['data'];
  className?: string;
}>();

const classList = computed(() => {
  return { error: props.input.error, icon: !!props.input.icon };
});
</script>

<template>
  <div class="common-input flex-column">
    <textarea
      v-if="input.type === 'textarea'"
      v-model="data[input.id]"
      :type="input.type"
      :class="[classList, className]"
      :placeholder="input.placeholder"
      :autofocus="!!input.autofocus"
      @keyup.enter="emit('dataManipulation')"
    />
    <common-input
      v-else
      v-bind="input"
      v-model="data[input.id]"
      @key-up-enter="emit('dataManipulation')"
    ></common-input>
    <component v-if="input.icon" :is="input.icon" size="24px"></component>
  </div>
</template>
